
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





































.speedtest__title {
  @extend %fw-bold;

  margin-top: $spacing * 2.5;
  font-family: $ff-alt;
  font-size: 2.8rem;
  line-height: 1.2;

  ::v-deep {
    strong {
      display: block;
      font-size: 3.2rem;

      @include mq(m) {
        font-size: 6.4rem;
      }
    }
  }

  @include mq(m) {
    font-size: 3.6rem;
  }
}
